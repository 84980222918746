import React from "react"
import styled from "styled-components"

let StyledButton = styled.button`
  height: 40px;
  width: ${ props => props.width ? props.width : '200px'};
  line-height: 40px;
  padding: 0 40px 0 20px;
  color: black;
  border: 1px solid black;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.2s ease-in-out; 
  position: relative;
  float: right;
  right: 0;
  margin-top: ${ props => props.marginTop ? props.marginTop : '20px'};
  cursor: pointer;
  svg{
    position: absolute;
    right: 15px;
    top: 13px;
    height: 12px;
    fill: black;
  }
  &:hover{
    background-color: black;
    color: white;
    svg{
      fill: white;
    }
  }
`
const Button = ({ width, children, marginTop }) => {
  return(
    <StyledButton
      width={ width }
      marginTop={ marginTop }
    >
        { children }
    </StyledButton>
  )
}
export default Button