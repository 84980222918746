import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import styled from "styled-components"
import Img from "gatsby-image"
import Button from "../components/button"
import RightArrow from '../images/right-arrow-thin.svg'
import MoreInfoTiles from "../components/more-info-tiles"

let ProductSection = styled.div`
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
  h2{
    margin: 0 auto 20px auto;
  }
  a{
    text-decoration: none;
  }
  &:last-of-type{
    padding-bottom: 0;
    margin-bottom: -30px;
  }
`
let ProductSectionHead = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 0 auto;
  display: inline-block;
`
let Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0 0 0;
`
let Tile = styled.div`
  cursor: pointer;
  background-color: white;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  h3{
    margin-bottom: 0;
    padding: 15px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    border: none;
    line-height: 1.4;
  }
  a{
    text-decoration: none;
  }
  &:only-child{
    margin: 0 auto 30px auto;
  }
  @media screen and (min-width: 600px){
    width: calc(50% - 10px);
  }
  @media screen and (min-width: 1000px){
    width: calc(33.333% - 15px);
  }
`
let ImgContainer = styled.div`
  padding: 15% 15% calc(15% + 50px) 15%;
  border: none;
`
let ButtonContainer = styled.div`
  width: 100%;
  padding: 0 25px 20px 0;
  position: absolute;
  bottom: 0;
  button{
    margin-top: 0;
  }
`

const ProductsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "products") {
                  id
                  productsPage {
                      title
                      text
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      products {
                          productCategoryName
                          productCategoryDescription
                          productCategoryLink {
                              url
                          }
                          product {
                              fieldGroupName
                              title
                              image {
                                  sourceUrl
                                  altText
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              link {
                                  url
                              }
                          }
                      }
                  }
                  seo {
                      title
                      metaDesc
                  }
              }
          }
      }
  `)

  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.productsPage

  return(
    <Page
        seoTitle={ seoData.title }
        seoDescription={ seoData.metaDesc }
    >
        <HeaderImage
            image={ pageData.headerImage }
        />

        <Section>
            <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
            <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>
        </Section>
        <Section
          backGColour={'whitesmoke'}
        >

            { pageData.products.map(({
                         productCategoryName,
                         productCategoryDescription,
                         productCategoryLink,
                         product
                     }, index ) => {
                return (

                  <ProductSection key={ index }>
                    <ProductSectionHead>
                      <h2 dangerouslySetInnerHTML={{ __html: productCategoryName }}/>
                      <p dangerouslySetInnerHTML={{ __html: productCategoryDescription }}/>

                      { productCategoryLink !== null &&
                        <a href={ productCategoryLink.url } target="_blank" rel="noopener noreferrer" >
                          <Button
                            width={ '230px' }
                          >
                            Find out more<RightArrow/>
                          </Button>
                        </a>
                      }

                    </ProductSectionHead>
                    <Tiles>

                        { product.map(({ title, image, link, }, index ) => {
                            return (

                              <Tile key={ index }>
                                  <h3 dangerouslySetInnerHTML={{ __html: title }}/>
                                  <ImgContainer>
                                    <Img
                                      fluid={ image.imageFile.childImageSharp.fluid }
                                      alt={ image.altText }
                                    />
                                  </ImgContainer>
                                  <ButtonContainer>
                                    <a href={ link.url } target="_blank" rel="noopener noreferrer">
                                      <Button
                                        width={ '190px' }
                                      >
                                        More info<RightArrow/>
                                      </Button>
                                    </a>
                                  </ButtonContainer>
                              </Tile>

                        )})}

                    </Tiles>
                  </ProductSection>

            )})}

        </Section>

        <Section backGColour={ 'white' }>
            <MoreInfoTiles
              location={ location }
            />
        </Section>
    </Page>
  )
}
export default ProductsPage
